export default {
  Dashboards: 'Gösterge Paneli',
  CRM: 'CRM',
  Analytics: 'Analitik',
  eCommerce: 'Ticaret',
  Calendar: 'Takvim',
  Chat: 'Sohbet',
  Email: 'E-posta',
  Invoice: 'Hesap ayarları',
  List: 'Liste',
  Preview: 'Ön izleme',
  Edit: 'Düzenlemek',
  Add: 'Eklemek',
  User: 'Kullanıcı',
  'User List': 'Kullanıcı listesi',
  'User View': 'Kullanıcı Görünümü',
  'APPS AND PAGES': 'Uygulamalar ve sayfa',
  Pages: 'Sayfalar',
  Authentication: 'Kimlik doğrulama',
  'Login V1': 'Giriş V1',
  'Login V2': 'Giriş V2',
  'Register V1': 'V1 kaydedin',
  'Register V2': 'V2 kaydet',
  'Forgot Password V1': 'Şifreyi unuttum v1',
  'Forgot Password V2': 'Şifreyi unuttum v2',
  'Reset Password V1': 'Parolayı sıfırla V1',
  'Reset Password V2': 'Parolayı sıfırla V2',
  Miscellaneous: 'Karışık',
  'Under Maintenance': 'Teknik çalışmalar',
  Error: 'Hata',
  'Coming Soon': 'Yakında gelecek',
  'Not Authorized': 'Yetkiniz yok',
  'Knowledge Base': 'BİLGİ TABANI',
  'Account Settings': 'Hesap ayarları',
  Pricing: 'Fiyatlar',
  FAQ: 'SSS',
  'USER INTERFACE': 'Kullanıcı arayüzü',
  Typography: 'Tipografi',
  Icons: 'İkonlar',
  Gamification: 'Oyunlaştırma',
  Cards: 'Kart',
  Basic: 'Temel',
  Statistics: 'İstatistik',
  Advance: 'İlerlemek',
  Actions: 'Eylemler',
  Chart: 'Çizelge',
  Components: 'Bileşenler',
  Alert: 'Uyarı',
  Avatar: 'Avatar',
  Badge: 'Rozet',
  Button: 'Düğme',
  Menu: 'Menü',
  'Expansion Panel': 'Genişleme paneli',
  Dialog: 'Diyalog',
  Pagination: 'Toplama',
  Tabs: 'Sekme',
  Tooltip: 'Araç ipucu',
  Chip: 'Yonga',
  Snackbar: 'Snackbar',
  Stepper: 'Step',
  Timeline: 'Zaman çizelgesi',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'Formlar ve Tablolar',
  'Forms Elements': 'Form öğeleri',
  Autocomplete: 'Otomatik tamamlama',
  Checkbox: 'Onay kutusu',
  Combobox: 'Açılan kutu',
  'File Input': 'Dosya girişi',
  Radio: 'Radyo',
  'Range Slider': 'Aralık kaydırıcısı',
  Select: 'Seçme',
  Slider: 'Kaydırıcı',
  Switch: 'Anahtar',
  Textarea: 'Textarea',
  Textfield: 'Metin alanı',
  'Date Picker': 'Tarih',
  'Month Picker': 'Ay',
  'Time Picker': 'Zaman seçici',
  Rating: 'Değerlendirme',
  'Form Layout': 'Form Düzeni',
  'Form validation': 'Form doğrulama',
  Tables: 'Tablolar',
  'Simple Table': 'Basit Masa',
  Datatable: 'Veri tablosu',
  'CHARTS AND MAPS': 'Grafikler ve haritalar',
  Charts: 'Grafikler',
  'Apex Chart': 'Apeks grafiği',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Broşür haritaları',
  Others: 'Diğer',
  'Menu Levels': 'Menü seviyeleri',
  'Menu Level 2.1': 'Menü seviyesi 2.1',
  'Menu Level 2.2': 'Menü seviyesi 2.2',
  'Menu Level 3.1': 'Menü seviyesi 3.1',
  'Menu Level 3.2': 'Menü seviyesi 3.2',
  'Disabled Menu': 'Devre dışı menü',
  'Raise Support': 'Destek Artırmak',
  Documentation: 'Belgeler',
  'Charts & Maps': 'Grafikler ve Haritalar',
  Apps: 'Uygulamalar',
  'User Interface': 'Kullanıcı arayüzü',
  'Forms & Tables': 'Formlar ve Tablolar',
  Welcome: 'Hoş geldiniz',
  Logout: 'Çıkış',
  PPControl: 'Paragrafların yönetimi',
  RateReport: "RK'nın temposu",
  VTRReport: 'Teftişler (VTR)',
  Advertisers: 'Reklamverenler',
  RateReportElements: {
    DateToToolTip:
      'Bitiş Tarihi, kampanya istatistiklerini etkiler ve tamamlanan kampanyaları, kampanyanın durma tarihine göre hariç tutar.',
    Advertiser: 'Reklamveren',
    CampaignId: 'Reklam kampanyası',
    CampaignName: 'Kampanyanın adı',
    UnitType: 'Satın alma birimleri',
    Fact: 'Gerçek',
    Plan: 'Plan',
    Prognosis: 'Tahmin Aşağı Dönüş, Aşırı Teslimat',
    UnitsPerDayNeed: 'Bir günde gerekli',
    DaysLeft: 'Sondan günler',
    CampaignDateStop: 'Son tarih',
  },
  VtrReportElements: {
    Advertiser: 'Reklamveren',
    CampaignId: 'Reklam kampanyası',
    CampaignName: 'Kampanyanın adı',
    CreativeName: 'Creative',
    Views: 'Görünüm',
    Clicks: 'Geçişler',
    ViewTo25: "% 25'e kadar izlenme",
    ViewTo50: "% 50'e kadar izlenme",
    ViewTo75: "% 75'e kadar izlenme",
    ViewTo100: "% 100'e kadar izlenme",
    Download: 'İndirmek',
  },
  TrackingCodeElements: {
    Id: 'ID',
    Domain: 'Domain',
    Advertiser: 'Reklamveren',
    Status: 'Durum',
    InActive: 'Aktif değil',
    Active: 'Aktif',
    Action: 'Hareketler',
    BtnAdd: 'Oluşturmak',
    BtnView: 'Gör',
    BtnEdit: 'Düzenle',
    BtnDelete: 'Sil',
    HeaderTextAdd: 'Ekle',
    HeaderTextView: 'İzle',
    HeaderTextEdit: 'Düzenle',
    HeaderTextDelete: 'Kaldır',
    BtnCopyClipboard: 'Kodu kopyala',
    BtnInstruction: 'Talimatlar',
    TooltipCopyClipboard: 'Kopyalanmış',
    DomainLabel: 'Domain',
    Pixel: 'Piksel',
    TextDeleteConfirm: 'Gerçekten kaldırmak istiyor musun?',
  },
  GeoElements: {
    Clicks: 'Geçişler',
    City: 'Şehir',
    HeaderTotalPeriod: 'Dönem için geçişler',
    HeaderTotalAll: 'Toplam geçişler',
    clicksFrom1To300: '1 ila 300',
    clicksFrom300To500: '300 ila 500',
    clicksFrom500To1000: '500 ila 1000',
    clicksFromGreater1000: "1000'den fazla",
  },
  AudienceElements: {
    CategoryName: 'Kategori',
    Percent: 'Yüzde',
    HeaderFemale: 'Bir kadın izleyicinin yaşa göre dağılımı:',
    HeaderMale: 'Bir erkek izleyicinin yaşa göre dağılımı:',
    Years: 'Yıllar',
  },
  ProviderElements: {
    Provider: 'Sağlayıcı',
    Views: 'Görünümler',
    Clicks: 'Geçiş',
    Visits: 'Oturumlar',
    Cost: 'Fiyat',
    Bounce: 'Iptal olanlar',
    Depth: 'Derinlik',
    Duration: 'Zaman Saniye',
    SelectProvider: 'Sağlayıcı',
  },
  DateTimePicker: {
    DateFrom: 'Tarih',
    DateTo: 'Tarihi',
    Today: 'Bugün',
    Yesterday: 'Dün',
    Week: 'Bir hafta',
    Month: 'Ay',
    AllPeriod: 'Tüm dönem',
  },
  BtnSearch: 'Arama',
  LblSearch: 'Arama',
  CampaignsList: 'Kampanya Listesi',
  CampaignStatistics: 'Temel veriler',
  CampaignGeo: 'Coğrafya',
  CampaignAuditory: 'Kitle',
  CampaingCreatives: 'Reklam öğeleri',
  CampaingCreativesDaily: 'Günlük rapor',
  CampaingCreativesVTR: 'VTR',
  CampaingProvidersReport: 'Tedarikçiler',
  Total: 'Genel',
  Geo: 'Coğrafya',
  Socdem: 'Sosyal',
  Interests: 'İlgi alanları',
  System: 'Sistem',
  Shows: 'Görünüm',
  References: 'Geçiş',
  City: 'Şehir',
  Country: 'Ülke',
  CTR: 'CTR',
  ShowUnactive: 'Aktif olmayan',
  Age: 'Yaş',
  Gender: 'Zemin',
  Female: 'Cinsiyet',
  Male: 'Erkek',
  Top10CategoriesHeader: 'En iyi 10 aktif ilgi kategorisi',
  Browser: 'Tarayıcı',
  OS: 'OC',
  Options: 'Seçenekler',
  Advertiser: 'Reklamveren',
  Сoverage: 'Kapsama alanı',
  CampaignsCount: 'Kampanya sayısı',
  ActiveCampigns: 'Aktif',
  PausedCampigns: 'Askıya alınmış',
  InactiveCampigns: 'Tamamlanmış',
  BtnSave: 'Kaydetmek',
  BtnCancel: 'İptal',
  BtnClose: 'Kapalı',
  BtnYes: 'Evet',
  BtnNo: 'Hayir',
  Required: 'Alan boş olmamalı',
  UrlRequired: 'Yalnış URL',
  Name: 'İsim',
  Viewability: 'Görünüm',
  Fraud: 'Dolandırıcılık',
  Start: 'Başlangıç',
  End: 'Bitiş',
  Status: 'Durum',
  Campaign: {
    Status: {
      Paused: 'Askıya alınmış',
      Active: 'Aktif',
      Inactive: 'Tamamlanmış',
      OnModaretion: 'Denetim altında',
    },
    Types: {
      Video: 'Video',
      Banners: 'Banner',
      Native: 'Native',
    },
    Locations: {
      Desktop: 'Masaüstü',
      Mobile: 'Mobil',
    },
  },
  Activity: 'Aktivite',
  IsWeekly: 'Haftalar içinde',
  BrandSafty: 'Marka Güvenliği',
  SaftyLevel2: 'Seviye 2 - Şiddetle İlişkili Normal Olmayan Kelime + Kelimeler',
  SaftyLevel3: 'Seviye 3 - Normal Olmayan Kelime + Şiddetle İlgili Kelimeler + Yetişkin konusundaki kelimeler',
  SaftyLevel4:
    'Seviye 4 - Normal Olmayan Kelime + Şiddetle İlgili Kelimeler + Yetişkinler + Siyasi ve Dini İçeriğin Konularında Kelimeler',
  SaftyLevel5:
    'Seviye 5-normatif kelime bilgisi + şiddet ile ilgili kelimeler + Yetişkinler + siyasi dinsel içerik konularında kelimeler + Müşteriden kişisel durdurma kelimesi',
  RestrictedWords: 'Yasak Morfoloji',
  BrandSaftyDescription:
    'Aşağıda, marka güvenliğinin gerçekleştirildiği ayak ve normal ifadelerin bir listesi bulunmaktadır.',
  Level2: 'Seviye 2',
  Level3: 'Seviye 3',
  Level4: 'Seviye 4',
  Level5: 'Seviye 5',
  Words: 'Kelimeler',
  AllPeriod: 'Tüm dönem boyunca',
  SelectedPeriod: 'Seçilen süre için',
  ShowsDecsription: 'Bir reklamın görüntüleme sayısı (afiş).',
  ReferencesDescription: 'Sistem tarafından kaydedilen afiş üzerindeki geçiş sayısı.',
  CTRDescription:
    'CTR banner üzerindeki tıklama sayısının görünümler sayısına oranı olarak tanımlanır, yüzde olarak ölçülür.',
  PIDescription: 'Tıklama oturumlarının oranı.',
  PI: 'Geçen indeks',
  CoverageDescription: 'Kapsanan reklam kampanyasının benzersiz ziyaretçilerinin sayısı.',
  RFDescription: 'Benzersiz bir kullanıcı ile reklam temas sıklığı.',
  RF: 'frekans',
  ViewabilityDescription:
    "Banner'ın gerçek şovunu ve görünürlük bölgesindeki konumunun zamanını izlemeye izin veren metrik",
  FraudDescription: 'Açıklama yok',
  Interactions: 'Etkileşime girdi.',
  InteractionsDescription: 'Açıklama yok',
  Views: 'Denetim',
  ViewsDescription: 'Açıklama yok',
  Coast: 'Fiyat',
  CoastDescription: 'Fiyat.',
  Installs: 'Kurulumlar',
  Purchases: 'Satınalmalar',
  Date: 'Tarih',
  DateFrom: 'Tarih',
  DateTo: 'Tarihi',
  StatisyicsForCampaign: 'RK İstatistikleri',
  CPC: 'CPC',
  CPM: 'CPM',
  ReportForPeriod: 'Dönem için rapor',
  ReportTotal: 'Toplam',
  Reports: 'Raporlar',
  Creative: 'Creative',
  SummarySheet: 'Konsolide sayfa',
  SummaryACData: "Rk'nın özet verileri",
  SummaryACData2: "Kazakistan Cumhuriyeti'nin toplam verileri",
  UniqUsers: 'Tekil kullanıcılar',
  Summary: 'Toplam',
  VTR: {
    SoundOff: 'Ses kapalı',
    SoundOn: 'Ses açık',
    PausePressed: 'Duraklar basıldı',
    ClousedByUser: 'Kullanıcı tarafından kapalı',
    SkipAdv: 'Reklamı kaçırmak için tıklayın',
  },
  EventGroups: 'Olay grupları',
  ViewDurationGroups: 'Süre Gruplarını Görüntüle',
  PrecentUnder10: '% 10',
  Precent11_25: '% 11-25',
  Precent26_50: '% 26-50',
  Precent51_75: '% 51-75',
  Precent76_99: '% 76-99',
  Precent100: '1',
  PrecentUpTo25: "% 25'e kadar izlenme",
  PrecentUpTo50: "% 50'e kadar izlenme",
  PrecentUpTo75: "% 75'e kadar izlenme",
  PrecentUpTo100: "% 100'e kadar izlenme",
  OpenVTR: "VTR'yi aç",
  VTRRequest: 'VTR Açılış İsteği',
  OpenVTRDescription: 'Şu anda, VTR bu kampanya için mevcut değil.',
  VTRReauestSended: 'Teşekkürler, isteğiniz gönderildi!',
  DaysDetails: 'Detaylandırma günleri',
  Watchups: 'Denetim',
  Events: 'Olaylar',
  CampaignStatisticsExcel: 'Kampanya İstatistikleri',
  CreativeStatisticsExcel: 'Yaratıcıdan İstatistikler',
  ViewShare: 'Görüntülemenin payı',
  Event: 'Etkinlik',
  AdvertisersGenerateReport: 'Rapor oluşturma',
  GenerateReport: 'Rapor Oluşturun',
  ReportGeneration: {
    Warning: 'Dikkat!',
    WarningText: 'Bu raporu gerçekten silmek istiyor musunuz?',
    DetailedInfo: 'Detaylı bilgi',
    Name: 'İsim',
    Parameters: 'Seçenekler',
    Campaigns: 'Kampanya',
    CreatedDate: 'Yaratılış tarihi',
    UpdatedDate: 'Değişim tarihi',
    Status: 'Durum',
    User: 'Kullanıcı',
    SelectAll: 'Hepsini seç',
    Actions: 'Hareketler',
    Save: 'Kaydetmek',
    Close: 'Kapalı',
    Statuses: {
      NEW: 'Yeni',
      IN_PROGRESS: 'İşlemde',
      COMPLETED: 'Tamamlanmış',
      FAILED: 'Reddedilmiş',
    },
    Details: 'Detaylar',
    Delete: 'Silmek',
    Download: 'İndirmek',
    CreateReport: 'Bir Rapor Oluşturun',
    CreateQuery: 'Bir istek oluştur',
    Summary: 'Özet',
    Tools: 'Aletler',
    ToolsReport: 'Enstrüman raporu',
    Banners: 'Banner',
    BannersReport: 'Yaratıcılık Raporu',
    Weeks: 'Haftalar',
    WeeksReport: 'Gündüz raporu',
    DaysDynamics: 'Dinamikler, günler',
    DaysDynamicsReport: 'Günlük Kampanya Dinamiği',
    RunnigUpSummary: 'Büyüyen Sonuç',
    RunnigUpSummaryReport: 'Sonuçları artırma kampanyasının dinamikleri',
    Geo: 'Coğrafya',
    GeoReport: 'İlk 25 Şehirler (Coğrafya)',
    Auditory: 'Kitle',
    AuditoryReport: 'Reklam kampanyasının kitlesi',
    ACReport: 'Bir reklam kampanyası yapacak',
    Advertiser: 'Reklamveren',
    Brand: 'Marka',
    CampaignPeriod: 'Reklam kampanyasının dönemi',
    ReportPeriod: 'Raporlama dönemi',
    From: 'Itibaren',
    To: 'İle',
    SummaryBannersShows: 'Toplam banner sayısı',
    SummaryClicks: 'Toplam tıklama sayısı',
    Coverage: 'Afişler her şeyi gördü, kullanıcılar (kapsam):',
    RF: 'Kullanıcı için ortalama görünüm sayısı (frekans):',
    CTR: 'Ortalama yanıt yüzdesi (CTR, %):',
    UniqUsers: 'Bannerlerden gelen tekil kullanıcılar',
    BannersCount: 'Rotasyondaki banner sayısı:',
    CampaingTools: 'Kampanya Araçları:',
    PlacementType: 'Yayın türü',
    Location: 'Konum',
    Tool: 'Alet',
    Shows: 'Görünümler',
    Clicks: 'Tiklama',
    CtrTable: 'CTR, %',
    CoverageTable: 'Kapsama alanı',
    Frequency: 'Sıklık',
    Баннеры: 'Banner',
    Видео: 'Video',
    Десктоп: 'Masaüstü',
    Мобайл: 'Mobil',
    'Десктоп + Мобайл': 'Masaüstü ve mobil',
    'Аудиторное размещение': 'Denetçi Yerleştirme',
    'look-a-like': 'Look-a-like / Benzer',
    Native: 'Native',
    'Кастомный сегмент': 'Özel segment',
    Ремаркетинг: 'Yeniden pazarlama',
    AdvertisingCampaign: 'Reklam kampanyası',
    Banner: 'Afiş',
    Понедельник: 'Pazartesi',
    Вторник: 'Salı',
    Среда: 'Çarşamba',
    Четверг: 'Perşembe',
    Пятница: 'Cuma',
    Суббота: 'Cumartesi',
    Воскресенье: 'Pazar',
    DayOfTheWeek: 'Haftanın günü',
    Date: 'Tarih',
    Viewability: 'Görünüm',
    Fraud: 'Dolandırıcılık',
    SummaryDays: 'Toplam',
    City: 'Şehir',
    AuditoryCharacteristics: 'İzleyicinin özellikleri',
    AuditoryInterests: 'Denetçi çıkarları',
    AuditoryLaL: 'L-A-L (benzeri arayın)',
    AuditoryRemarketing: 'Yeniden pazarlama',
    M: 'Erkekler',
    W: 'Kadınlar',
    Gender: 'Cinsiyet',
    Age: 'Yaş',
    Interests: 'İlgi alanları',
    Loading: 'Verileri işleriz',
  },
  Login: 'Giriş',
  Password: 'Şifre',
  LoginError: 'Girilemiyor. Yanlış kullanıcı adı veya şifre',
  SignIn: 'Giriş yap',
  Users: 'Kullanıcılar',
  LinkUser: 'Bağlayıcı',
  ReportGenerationMenu: 'Rapor oluşturma',
  Notifications: 'Bildirimler',
  NewNotifications: 'Yeni',
  RemoveAllNotifications: 'Her şeyi kaldır',
  ReadAllNotifications: 'Her şeyi oku',
  NoNotificationsYet: 'Henüz değil',
  Profile: 'Profil',
  CampaingType: 'Kampanya türü',
  Location: 'Konum',
  Provider: 'Sağlayıcı',
  NoPlotDataAlert: 'Avantajlı grafikler için veri yoktur.',
  DisplayType: 'Ekran türü',
  CampaignFilter: 'Kampanya',
  EmptyCampaignId: 'Kampanya seçilmedi. Ekran için bir kampanya seçin.',
  CreativeVTRStatistics: 'İstatistikler VTR',
  UserPage: {
    SavedSuccesfully: 'Veriler başarıyla kaydedildi',
    Create: 'Yaratmak',
    UserInfo: 'Kullanıcı Muhasebe Verileri',
    ChangePassword: 'Şifre değiştir',
    UserCreation: 'Bir Kullanıcı Oluşturma',
    UserEdit: 'Kullanıcı Düzenleme',
    Validation: {
      NameRequired: 'Doldurmak için kullanıcı adı zorunludur',
      EmailRequired: 'Doldurmak için e -posta gerekiyor',
      EmailIncorrect: 'E-Posta düzeltilemez',
      RoleRequired: 'Doldurmak için rol zorunludur',
      PasswordRequired: 'Doldurmak için şifre gereklidir',
      PasswordNotMatch: 'Şifre eşleşmiyor',
      PasswordLength: 'Parolanın uzunluğu minimum 6 karakter olmalıdır',
      EmailExists: 'Bu e-posta kullanılmaktadır. Lütfen başka bir e-posta girin',
      PasswordChangesSuccess: 'Parola başarıyla değiştirildi',
      CabinetRequired:
        '"Yönetici" rolüne sahip bir kullanıcı için LC\'den birine erişiminiz gerekir (biraz LC seçebilirsiniz)',
    },
    UserName: 'İsim',
    Email: 'E-posta',
    UpdatePassword: 'Parolayı güncelle',
    Role: 'Rol',
    Password: 'Şifre',
    NewPassword: 'Yeni Şifre',
    ConfirmPassword: 'Şifreyi Onayla',
    Save: 'Kaydet',
    Cancel: 'İptal',
    Agency: 'Ajans',
    Status: 'Durum',
    UserRules: 'Kullanıcı erişimi',
    TechDomain: 'Teknik alan',
    EditRules: 'Erişim Kurallarını Düzenle',
    Actions: 'Hareketler',
    Roles: {
      1: 'Root',
      5: 'Reklamverenler',
      2: 'Süper yöneticiler',
      3: 'Yöneticiler',
      4: 'Yöneticiler Yöneticiler',
      8: 'Tedarikçiler',
      6: 'Yöneticiler',
      7: 'Reklamveren kullanıcılar',
      ROOT: 'Root',
      ADVERTISERS: 'Reklamverenler',
      SUPER_ADMINS: 'Süper yöneticiler',
      ADMINS: 'Yöneticiler',
      MANAGER_ADMINS: 'Yöneticiler Yöneticiler',
      PROVIDERS: 'Tedarikçiler',
      MANAGERS: 'Yöneticiler',
      ADVERTISERS_USERS: 'Reklamveren kullanıcıları',
    },
    Edit: 'Düzenle',
    LinkUsers: 'Kullanıcıları bağlayın',
    Delete: 'Sil',
    Statuses: {
      Active: 'Aktif',
      Inactive: 'Aktif değil',
    },
    UserDeleted: 'Kullanıcı başarıyla silinir',
    Warning: 'Dikkat!',
    WarningText: 'Bu kullanıcıyı gerçekten kaldırmak istiyor musunuz?',
    WarningCantDeleteText: 'En az bir kampanyası olduğu için bir kullanıcıyı silmek imkansızdır!',
    Yes: 'Evet',
    No: 'Hayir',
    LinkToUser: 'Kullanıcıya bağlan',
  },
  UsersLink: 'Bağlayıcı Kullanıcılar',
  ProfilePage: {
    Profile: 'Profil',
    ChangePassword: 'Şifre değiştir',
    UserName: 'İsim',
    Email: 'E-postasi',
    OldPassword: 'Mevcut Şifre',
    NewPassword: 'Yeni Şifre',
    ConfirmPassword: 'Şifreyi Onayla',
    Role: 'Rol',
    Validation: {
      IncoreectOldPassword: 'Mevcut şifre yanlış',
      UserUpdatedSuccess: 'Veriler başarıyla güncellendi',
    },
    Save: 'Değişiklikleri Kaydet',
    Cancel: 'İptal',
    UploadAvatar: 'Yeni bir fotoğraf yükleyin',
    AvatarRequerments: 'İzin verilen JPG, JPEG veya PNG',
    Roles: {
      1: 'Root',
      5: 'Reklamverenler',
      2: 'Süper yöneticiler',
      3: 'Yöneticiler',
      4: 'Yöneticiler Yöneticiler',
      8: 'Tedarikçiler',
      6: 'Yöneticiler',
      7: 'Reklamveren kullanıcılar',
      ROOT: 'Root',
      ADVERTISERS: 'Reklamverenler',
      SUPER_ADMINS: 'Süper yöneticiler',
      ADMINS: 'Yöneticiler',
      MANAGER_ADMINS: 'Yöneticiler Yöneticiler',
      PROVIDERS: 'Tedarikçiler',
      MANAGERS: 'Yöneticiler',
      ADVERTISERS_USERS: 'Reklamveren kullanıcıları',
    },
  },
  StatExcelHeader: 'Reklam Kampanyaları İstatistikleri',
  CampaignListExcelHeader: 'Reklam Kampanyaları',
  VTRExcelHeader: 'VTR raporu',
  ProvidersExcelHeader: 'Tedarikçi Raporu',
  Показы: 'Görünüm',
  Переходы: 'Geçişler',
}
